import React, { Component } from 'react';
import axios from 'axios';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';


function appendLeadingZeroes(n){
  if(n <= 9){
    return "0" + n;
  }
  return n
}

class TaskTable extends Component {
  state = {
    posts: [],
    isLoading: true,
    error: null,
  };


  


  getTaskInfo() {

    var emailAddress = this.props.userName;

    var epicorBaseURL  = process.env.REACT_APP_EPICOR_BASE_URL;
    var epicorBaqID  = process.env.REACT_APP_EPICOR_BAQ_ID;
    var epicorAPIKey  = process.env.REACT_APP_EPICOR_API_Key;
    var epicorAuth  = process.env.REACT_APP_EPICOR_AUTH;
    var epicorCompany = process.env.REACT_APP_EPICOR_Company;

    var config = {
      method: 'get',
      //url: 'https://epicor.clearbusinessoutcome.com/ERPLive/api/v2/odata/CBO/BaqSvc/Int-TeamsTaskIntegration/Data?$filter=AssignedToSalesRep_EMailAddress eq \'' + emailAddress + '\'&$count=true',
      url: `${epicorBaseURL}/api/v2/odata/${epicorCompany}/BaqSvc/${epicorBaqID}/Data?$filter=AssignedToSalesRep_EMailAddress eq '${emailAddress}'&$count=true`,
      headers: { 
        'X-API-Key': epicorAPIKey, 
        'Authorization': epicorAuth
      }
    };
    axios(config)
      .then(response => {
        const tasks = response.data.value;
        const posts = [];
        for (const task of tasks) {
            posts.push({ task });
        };
        this.setState({
          posts,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getTaskInfo();
  }
  render() {
    const { isLoading, posts } = this.state;
    const columns = [{
      Header: 'Task',
      columns: [
        {
          Header: 'ID',
          accessor: 'task.Task_TaskID_c'//,
          //Cell: props => <a href='https://teams.microsoft.com/l/chat/0/0?users=joe@contoso.com&message=Hi%20folks%2C%20kicking%20off%20a%20chat%20about%20our%20meeting%20tomorrow'>{props.value}</a>
        },
        {
          Header:"Comment",
          accessor: "task.Task_TaskComment"
        },
        {
          Header: "Onsite",
          accessor: "task.Task_Onsite_c",
          Cell: props => <span >{props.value === true ? "Onsite" : "Remote"}</span>,
          width: 100,
          minWidth: 100,
        },
        {
          Header: "Hrs",
          accessor: "task.Task_Hrs_c",
          Cell: props => <div style={{
            textAlign: "right",
          }}>{props.value.toFixed(2)}</div>,
          width: 50,
          minWidth: 50,
        },
        {
          Header: "Hrs Booked",
          accessor: "task.Calculated_HrsBooked",
          Cell: props => <div style={{
            textAlign: "right",
          }}>{props.value.toFixed(2)}</div>,
          width: 100,
          minWidth: 100,
        },
        {
          Header: "Hrs Remain",
          accessor: "task.Calculated_HrsRemaining",
          Cell: props => <div style={{
            textAlign: "right",
          }}>{props.value.toFixed(2)}</div>,
          width: 100,
          minWidth: 100,
        },
        {
          Header: "Due Date",
          accessor: "task.Task_DueDate",
          Cell: props => <div>{appendLeadingZeroes(new Date(props.value).getDate()) + "/" + (appendLeadingZeroes(new Date(props.value).getMonth() + 1)) + "/" + new Date(props.value).getFullYear()}</div>
        }
      ]
    },
    {
      Header: "Project",
      columns: [
        {
          Header: 'ID',
          accessor: 'task.Task_Key1'
        },
        {
          Header: 'Description',
          accessor: 'task.Project_Description',
        },
        {
          Header: 'PM',
          accessor: 'task.SalesRep_Name'
        }
      ]
    },
    {
      Header: "Project Phase",
      columns: [
        {
          Header: 'ID',
          accessor: 'task.Task_Key2'
        },
        {
          Header: 'Description',
          accessor: 'task.ProjPhase_Description',
        }
      ]
    },
    {
      Header: 'Customer',
      columns: [
        {
          Header: 'ID',
          accessor: 'task.Customer_CustID'
        },
        {
          Header: 'Name',
          accessor: 'task.Customer_Name'
        }
      ]
    }
  ]

    return (
      <div>
        <h1>Below are your open tasks, you currently have {posts.length}. </h1>
          <ReactTable
            data={posts}
            columns={columns}
            defaultPageSize={10}
            pageSizeOptions={[10, 20, 30]}
            filterable={true}
            loading={isLoading}

        />
      </div>
      );
  }
}

export default TaskTable;